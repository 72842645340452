.page {
    display: flex;
    flex-direction: row; /* default to side by side */
    justify-content: space-between;
}

.leaderboard {
    width: 30%;
    flex: 0.3;
}

.games {
    flex: 0.7;
}

@media (max-width: 800px) { /* adjust this value based on your needs */
    .page {
        flex-direction: column-reverse; /* stack vertically on small screens */
    }
    .leaderboard {
        width: 100%; /* full width on small screens */
        flex: 1; /* take up full width */
    }
    .games {
        width: 100%; /* full width on small screens */
        flex: 1; /* take up full width */
    }
}



