.top_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, #28529E, #142D5C);
    color: white;
    padding: 0px 5px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
    box-sizing: border-box;
}

.center{
    width: 33%;
    text-align: center;
}

.left {
    width: 33%;
    text-align: left;
    text-decoration: none;
}

.logo {
    max-width: 100%;
    text-align: center;
    max-height: 15vh;
    width: auto;
}


.blason {
    max-width: 100%;
    text-align: center;
    max-height: 15vh;
    width: auto;
    /*box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);*/
}

.right {
    width: 33%;
    text-align: right;

}


.button {
    max-width: 100%;
    color: white;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    vertical-align: central;

}

.userDisplay{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
