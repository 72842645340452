.commonButton {
    padding: 8px 10px;
    border-radius: 10px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border: 2px solid;
}

.radioButton {
    border-color: #007BFF;
    background: #fff;
}

.running {
    background: #4b4b4b;
    color: #fff;
    border-color: #4b4b4b;
}

.success {
    background: #14930d;
    color: #fff;
    border-color: #14930d;
}

.lost {
    background: #bd1111;
    color: #fff;
    border-color: #bd1111;
}


.list {
    list-style-type: none;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1em;
    flex-direction: row;
}

.row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
}

.verticalFlex {
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    vertical-align: central;
}
