.gameList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 1rem;
    align-items: center;
    vertical-align: center;
}

@media (min-width: 600px) {
    .gameList {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (min-width: 900px) {
    .gameList {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
}
