.board {
    width: 20%;
    text-align: center;
}

.row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.listItem img {
    width: 20%;
    border-radius: 50%;
}

.button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    vertical-align: central;
}
