.team {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 3.75rem;
}

.teamHorizontal{
    flex: 1;
    display: flex;
    flex-direction: row;
    min-width: 3.75rem;
    align-items: center;
}

.flag{
    align-self: center;
    width: 60px;
    height: 50px;
    object-fit: contain;
}


