.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    width: 75%;
    border-radius: 5px;
    padding: 20px;
    margin: auto;
    margin-top: 15px;
    background-color: #a7b5f6;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.card h2 {
    color: #333;
    margin-bottom: 10px;
    margin-top: 1px;
}

.card p {
    color: #666;
}

.teamView {
    max-width: 20%;
}

.team {
    display: flex;
    flex-direction: column;
    min-width: 3.75rem;
}

.flag {
    width: 60px;
    height: 50px;
    object-fit: contain;
}

.button {
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    vertical-align: central;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.container_vertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    color: green;
    text-align: center;
}

.commonButton {
    padding: 12px 16px;
    border-radius: 10px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border: 2px solid;
}

.spCommonButton {
    padding: 3px 8px;
    border-radius: 10px;
    margin: 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    border: 2px solid;
}


.radioButton {
    border-color: #007BFF;
    background: #fff;
}

.selected {
    background: #007BFF;
    color: #fff;
    border-color: #007BFF;
}

.validated {
    background: #e17800;
    color: #fff;
    border-color: #e17800;
}

.running {
    background: #4b4b4b;
    color: #fff;
    border-color: #4b4b4b;
}

.success {
    background: #14930d;
    color: #fff;
    border-color: #14930d;
}

.lost {
    background: #bd1111;
    color: #fff;
    border-color: #bd1111;
}

.validation {
    padding: 12px 3px;
    border-color: #007BFF;
    background: #fff;
}


.list {
    list-style-type: none;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1em;
}


.sp_list {
    list-style-type: none;
    padding: 0;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.1em;
    flex-direction: column;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjust as needed */
    gap: 20px; /* adjust as needed */
}

.verticalFlex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.horizontalFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.inputShort {
    width: 50%; /* Adjust this value as needed */
}

.verticalList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.verticalLeft {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

